import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

const TextInColumns = ({ slice }) => {
  return (
    <div className={`component-${slice.slice_type}`}>
      {slice.items.map((content, index) => {
        return (
          <div className='md:mt-8 lg:grid lg:grid-cols-2 lg:gap-8' key={`content-${index}`}>
            <div className='relative lg:row-start-1 lg:col-start-2'>
              {content.image.fluid && (
                <div className='relative text-base mx-auto max-w-prose lg:max-w-none'>
                  <figure>
                    <div className='aspect-w-12 aspect-h-7 lg:aspect-none'>
                      <Img
                        className='rounded-lg shadow-lg object-cover object-center'
                        fluid={content.image.fluid}
                        alt={content.image.alt}
                      />
                    </div>
                  </figure>
                </div>
              )}
            </div>
            <div className='mt-8 lg:mt-0'>
              <div className='prose prose-indigo mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1' dangerouslySetInnerHTML={{__html:content.text.html}} />
            </div>
          </div>
        )
      })}
    </div>
  )
}

TextInColumns.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default TextInColumns
